import React, { useEffect, useRef } from "react";

const App = ({ children }) => {
  const initGTM = useRef(null);
  const initGTMOnEvent = useRef(null);

  useEffect(() => {
    initGTM.current = () => {
      if (window.gtmDidInit) {
        return;
      }
      window.gtmDidInit = true;

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "gtm.js",
          "gtm.start": new Date().getTime(),
          "gtm.uniqueEventId": 0,
        });
      };
      script.src = "https://www.googletagmanager.com/gtm.js?id=GTM-T68VMBDR";
      document.head.appendChild(script);
    };

    initGTMOnEvent.current = (event) => {
      initGTM.current();
      event.currentTarget.removeEventListener(
        event.type,
        initGTMOnEvent.current
      );
    };

    // const initGTMAfterDelay = setTimeout(initGTM.current, 3500)

    const handleScroll = initGTMOnEvent.current;
    const handleMouseMove = initGTMOnEvent.current;
    const handleTouchStart = initGTMOnEvent.current;

    document.addEventListener("scroll", handleScroll);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("touchstart", handleTouchStart);

    return () => {
      // clearTimeout(initGTMAfterDelay)
      document.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("touchstart", handleTouchStart);
    };
  }, []);

  return <>{children}</>;
};
export const wrapRootElement = ({ element }) => {
  return <App>{element}</App>;
};
